<template>
  <div>
    <Navbar page="Gestão de Requisições" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">
      <h3 v-if="!dpo" class="text-lg font-semibold my-3 mb-4">Empresa sem DPO selecionado <small class="text-red-700">(Para gestão de requisição é obrigatório o cadastro de DPO)</small></h3>
      <div class="grid grid-cols-12 gap-x-6 gap-y-2 mb-3">
        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <button
            @click="$modal.show('novaRequisicao')"
            type="button"
            class="transition duration-200 bg-yellow-300 hover:bg-yellow-400 focus:bg-yellow-700 focus:shadow-sm focus:ring-4 focus:ring-yellow-500 focus:ring-opacity-50 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center w-full"
            :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }"
          >
            <span class="inline-block mr-2">Gerar requisição manual</span>
          </button>
        </div>
        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <button
            @click="getCodigo"
            type="button"
            class="transition duration-200 bg-blue-300 hover:bg-blue-400 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center w-full"
          >
            <span class="inline-block mr-2">Plugin para o site</span>
          </button>
        </div>
        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <router-link to="/requisicao/direitos" type="button" class="transition duration-200 bg-indigo-500 hover:bg-indigo-700 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center mb-2">
            <span class="inline-block">Tipos Solicitações</span>
          </router-link>
        </div>
        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <button @click="$modal.show('importarModal')" class="bg-green-400 hover:bg-green-500 focus:bg-green-500 focus:shadow-sm focus:ring-4 text-white w-full flex py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-40 font-semibold justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mt-1 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
            </svg> 
            Importar requisições 
          </button>
        </div>
        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <router-link to="/requisicao/config" type="button" class="transition duration-200 bg-gray-500 hover:bg-gray-700 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center mb-2">
            <span class="inline-block">Configuração Plugin</span>
          </router-link>
         </div>
        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <button @click="excluirSelecionadas" v-if="selecionados.length" type="button" class="transition duration-200 bg-red-500 hover:bg-red-700 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center mb-2">
            <span class="inline-block">Arquivar selecionadas</span>
          </button>
        </div>
      </div>

      <div class="grid grid-cols-12 gap-6 mb-3">
        <div class="col-span-12 xl:col-span-10 mb-1 mt-1">
          <input
            v-model="busca"
            type="text"
            name="busca"
            id="busca"
            placeholder="Filtrando Solicitante"
            class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"
          />
        </div>
        
        <div class="col-span-12 md:col-span-2 mb-1 mt-2">
         
          <button
            @click="start"
            class="bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
          >
            Buscar
          </button>
        </div>
        <div class="col-span-12">
          <label for="arquivadas" class="text-sm mr-2">
          <input @change="start" v-model="arquivadas" type="checkbox" id="arquivadas" class="rounded-sm">
          Listar arquivadas
          </label>
        </div>
      </div>

      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer">
                      <svg @click="selecionarTodos" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Protocolo
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Solicitante
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Tipo de requisição
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Data
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Prazo
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Data Resposta
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Opções
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="item in list" :key="item._id">
                    <td class="px-3 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <input :checked="selecionados.map(el => el._id).indexOf(item._id) > -1" @change="(e) => selecionadosControl(item, e)" type="checkbox" class="rounded-sm">
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="text-sm text-gray-500">
                          {{ item.protocolo ? item.protocolo : "" }}
                        </div>
                      </div>
                    </td>

                    <td class="px-6 py-4">
                      <div class="flex items-center">
                        <div class="text-sm text-gray-500">
                          {{ item.nome }}
                        </div>
                      </div>
                    </td>

                    <td class="px-6 py-4">
                      <div class="flex items-center">
                        <div class="text-sm text-gray-500">
                          {{ item.direito ? item.direito.direito : "" }}
                        </div>
                      </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div v-if="item.data" class="text-sm text-gray-500">
                          {{ item.data | moment("DD/MM/YYYY HH:mm") }}
                        </div>
                      </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div v-if="item.data && item.status !== 'Respondido'" class="text-sm text-gray-500">
                          {{getDiff(item.data)}}
                        </div>
                        <div v-else-if="item.status === 'Respondido'" class="text-sm text-gray-500">
                          Finalizado
                        </div>
                      </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div
                          v-if="item.status === 'Pendente'"
                          class="text-sm font-semibold text-pendente"
                        >
                          {{ item.status ? item.status : "" }}
                        </div>
                        <div v-else class="text-sm font-semibold text-concluido">
                          {{ item.status ? item.status : "" }}
                        </div>
                      </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div v-if="item.dataFim" class="text-sm font-semibold text-concluido">
                          {{ item.dataFim | moment("DD/MM/YYYY HH:mm")}}
                        </div>
                      </div>
                    </td>

                    <td class="px-6 py-4 text-sm font-medium">
                      <router-link
                        :to="`/${route}/analisar/${item._id}`"
                        @click="remove(item._id)"
                        type="button"
                        class="text-white hover:bg-blue-500 bg-blue-700 rounded py-1 px-4 mx-1 mt-1"
                      >
                        Analisar
                      </router-link>

                      <button
                        v-if="!arquivadas"
                        @click="remove(item._id)"
                        type="button"
                        class="text-white hover:bg-red-500 bg-red-700 rounded py-1 px-4 mx-1 mt-1"
                      >
                        Arquivar
                      </button>
                      <button
                        v-if="arquivadas"
                        @click="ativar(item._id)"
                        type="button"
                        class="text-white hover:bg-gray-800 bg-gray-700 rounded py-1 px-4 mx-1 mt-1"
                      >
                        Ativar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination
              v-model="page"
              :per-page="perPage"
              :records="total"
              @paginate="setPage"
              class="pagination"
            />
          </div>
        </div>
      </div>


      <modal name="novaRequisicao" :width="800" :adaptive="true" :height="'auto'" :clickToClose="false">
        <div class="px-3 py-4">
          <h2 class="text-2xl font-bold mb-3">Nova Requisição de titular</h2>
          <div class="grid grid-cols-12 gap-3">

            <div class="col-span-12 md:col-span-4">
              <label for="tipoTitular" class="block text-sm font-medium">Tipo do titular</label>
              <select required v-model="form.tipoTitular" name="tipoTitular" id="tipoTitular" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                <option v-for="tipo in tiposTitulares" :key="tipo._id" :value="tipo._id">{{tipo.tipo}}</option>
              </select>
            </div>

            <div class="col-span-12 md:col-span-4">
              <label for="solicitacao" class="block text-sm font-medium">Solicitação</label>
              <select required v-model="form.solicitacao" name="solicitacao" id="solicitacao" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                  <option v-for="solicitacao in direitos" :key="solicitacao._id" :value="solicitacao.ref">{{ solicitacao.direito }}</option>
              </select>
            </div>

            <div class="col-span-12 md:col-span-4">
              <label for="origem" class="block text-sm font-medium">Origem</label>
              <select required v-model="form.origem" name="origem" id="origem" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                <option value="Site">Site</option>
                <option value="Email">Email</option>
                <option value="Telefone">Telefone</option>
                <option value="Pessoalmente">Pessoalmente</option>
                <option value="Interna">Interna</option>
                <option value="Outro">Outro</option>
              </select>
            </div>
          </div>

          <div class="grid grid-cols-12 gap-3 mt-3">
            <div class="col-span-12 md:col-span-5">
                <label for="nome" class="block text-sm font-medium">Primeiro e último nome</label>
                <input type="text" v-model="form.nome" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
            </div> 

            <div class="col-span-12 md:col-span-3">
                <label for="cpf_cnpj" class="block text-sm font-medium">3 últimos digitos do CPF</label>
                <input type="text" v-model="form.cpf_cnpj" name="cpf_cnpj" id="cpf_cnpj" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
            </div> 

            <div class="col-span-12 md:col-span-4">
                <label for="telefone" class="block text-sm font-medium">Telefone</label>
                <input type="text" v-model="form.telefone" name="telefone" id="telefone" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
            </div>
          </div>
          <div class="grid grid-cols-12 gap-3 mt-3">
            <div class="col-span-12 md:col-span-6">
                <label for="email" class="block text-sm font-medium">Email</label>
                <input type="text" v-model="form.email" name="email" id="email" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
            </div>  
          </div>
          <div class="grid grid-cols-12 gap-3 mt-3">
            <div class="col-span-12">
              <label for="descricao" class="block text-sm font-medium">Detalhes da solicitação</label>
              <textarea v-model="form.descricao" type="text" name="descricao" id="descricao" rows="3" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg"></textarea>
            </div>
          </div>
          <div class="grid grid-cols-2 mt-5">
            <div class="text-left">
                <button @click="$modal.hide('novaRequisicao')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    Voltar
                </button>
            </div>
            <div class="text-right">
                <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                    Salvar
                </button>
            </div>
          </div>
        </div>
      </modal>

      <modal name="pluginSite" :adaptive="true" :height="'auto'">
        <div class="px-5 py-8">
          <h2 class="text-2xl font-bold mb-5">Plugin para o site</h2>
          <div class="grid grid-cols-12 gap-4">

            <div class="col-span-12">
              <label for="codigo" class="block text-sm font-medium">Copie o código abaixo e cole <b class="text-blue-800">no final da body html</b> do seu site para gerenciamento de solicitações de titulares e canal de denúncia de incidentes.</label>
              <textarea v-model="codigo" type="text" name="codigo" id="codigo" rows="4" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg"></textarea>
            </div>
            
          </div>

          <div class="grid grid-cols-2 mt-5">
            <div class="text-left">
                <button @click="$modal.hide('pluginSite')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    Voltar
                </button>
            </div>
          </div>
        </div>
      </modal>

      <modal name="importarModal" :adaptive="true" :height="'auto'">
        <div class="px-5 py-5">
          <h2 class="text-xl mb-2 font-bold mb-2"> Importar base</h2>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12">
                <a target= "_blank" rel="noreferrer" :href="`/arquivo-modelo-15.csv`" class="text-sm underline">Exemplo arquivo modelo importação</a>
            </div>
            <div class="col-span-12">
              <vue-dropzone ref="doc" id="doc" class="w-full" :options="dropzoneOptions" @vdropzone-sending="send" v-on:vdropzone-success="update">
              </vue-dropzone>
            </div>
          </div>
        </div>
      </modal>

    </div>
  </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
export default {
  components: {
    vueDropzone
  },
  data() {
    return {
      route: "requisicao",
      form: {
        solicitacao: '',
        nome: '',
        cpf_cnpj: '',
        email: '',
        telefone: '',
        origem: 'Site',
        descricao: '',
        tipoTitular: '',
      },
      titulares: [],
      atividades: [],
      tiposTitulares: [],
      direitos: [],
      list: [],
      dpo: null,
      selecionados: [],
      page: 1,
      perPage: 10,
      busca: "",
      arquivadas: false,
      codigo: "",
      total: 0,
      dropzoneOptions: this.$http.getDropzoneConfig(
        `v1/requisicao/importar-csv`,
        'post',
      {
        maxFiles: 1,
        addRemoveLinks: true,
        capture: true,
        dictDefaultMessage: 'Clique ou arraste seu arquivo aqui',
        dictRemoveFile: 'Remover'
      }),
    };
  },
  methods: {
    async start() {
      const listReq = await this.$http.post(`/v1/${this.route}/list`, {
        busca: this.busca,
        limit: this.perPage,
        arquivadas: this.arquivadas
      });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
      this.codigo = listReq.data.codigo;
      
      const direitosReq = await this.$http.post(`/v1/direitos/list`, {});
      this.direitos = direitosReq.data.data;

      const reqgetDPO = await this.$http.post(`/v1/${this.route}/getDPO`);
      this.dpo = reqgetDPO.data;
    },

    getDiff(data) {
      const qtd = this.$moment(data).add(15, 'days').diff(this.$moment(),"days");
      return qtd > -1 ? `${qtd} ${qtd > 1 ? 'dias' : 'dia'}` : 'Finalizado';
    },

    async excluirSelecionadas(){
      this.$confirm({
        title: 'Arquivar selecionados',
        message: `Deseja arquivar itens selecionados ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.post(`/v1/${this.route}/selecionados/deletar`,{ selecionados: this.selecionados });
          this.selecionados = [];
          this.$vToastify.success("Removido com sucesso!");
          this.start();
          this.grafico();
        }
      })
    },

    async ativar(id) {
      await this.$http.put(`/v1/${this.route}/`, { _id: id, ativo: true });
      this.$vToastify.success("Ativada com sucesso!");
      this.start();
    },

    async remove(id) {
      await this.$http.delete(`/v1/${this.route}/${id}`);
      this.$vToastify.success("Arquivado com sucesso!");
      this.start();
    },

    async save() {
      const req = await this.$http.post(`/v1/${this.route}`, this.form);
      if (req.data.success) {
          this.$vToastify.success("Gerado com sucesso!");
          this.$modal.hide('novaRequisicao')
          this.start();
      } else {
          this.$vToastify.error(req.data.err);
      }
    },

    async getCodigo() {
      const req = await this.$http.post(`/getCodigoPlugin`);
      this.codigo = req.data;
      this.$modal.show('pluginSite');
    },

    async selecionarTodos(){
      if (this.selecionados.length) {
        this.selecionados = [];
      } else {
        const listReq = await this.$http.post(`/v1/${this.route}/list`, { all: true, busca: this.busca });
        this.selecionados = listReq.data.data;
      }
    },

    selecionadosControl(item, e){
      if (this.selecionados.map(el => el._id).indexOf(item._id) > -1 && !e.target.checked) {
        this.selecionados.splice(this.selecionados.map(el => el._id).indexOf(item._id), 1);
      } else {
        this.selecionados.push(item);
      }
    },

    async setPage(page) {
      this.page = page;
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      const listReq = await this.$http.post(`/v1/${this.route}/list`, { limit, skip });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },

    send(file, xhr, formData) {
      formData.append('empresa', this.$store.state.empresa && this.$store.state.empresa._id ? this.$store.state.empresa._id : null);
    },

    update(file, response) {
      console.log("response", response);
      if(response.success) this.$vToastify.success(response.msg);
      else this.$vToastify.error(response.err);
      this.$modal.hide('importarModal');
      this.start();
    },

  },
  async beforeMount() {
    this.start();

    const titularesReq = await this.$http.post(`/v1/titulares/list`, { all: true });
    this.titulares = titularesReq.data.data;

    const reqtipos = await this.$http.post('/v1/tipotitulares/list', { all: true });
    this.tiposTitulares = reqtipos.data.data;
    
  },
};
</script>
